import React, { createElement, useRef, useState } from "react"
import { domToReact } from "html-react-parser"
import styled from "styled-components"

const HeadingContainer = styled.div`
  width: 100%;
  align-items: center;
  position: relative;

  i {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2.5rem;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    opacity: 0;
    transition: 0.2s all ease;
    line-height: 2;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
      transition: 0.2s all ease;
    }
  }

  &:hover {
    i {
      opacity: 1;
    }
  }

  input {
    position: fixed;
    left: -1000px;
  }
`

const ArticleHeading = ({ heading }) => {
  const [copied, setCopied] = useState(false)
  const inputRef = useRef(null)
  const headingPath = `${window.location.origin}${window.location.pathname}#${heading.attribs.id}`
  const headingText = domToReact(heading.children)
  const linkClassName = copied ? "fa fa-yarno-unlink" : "fa fa-yarno-link"

  const copyLinkToClipboard = () => {
    inputRef.current.select()
    document.execCommand("copy")
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <HeadingContainer headingName={heading.name}>
      <input
        type="text"
        readOnly
        value={headingPath}
        ref={inputRef}
        name="article-heading"
      />
      { createElement(heading.name, { id: heading.attribs.id }, headingText) }
      <i className={linkClassName} onClick={copyLinkToClipboard} />
    </HeadingContainer>
  )
}

export default ArticleHeading
