import React, { Fragment } from "react"
import Link from "shared/components/Link"
import styled from "styled-components"
import { sortBy } from "lodash"

const Container = styled.div`
  width: 100%;
  margin: 3rem 0 5rem 0;
  color: ${props => props.theme.colors.primary};
  padding-top: 1rem;

  h4 {
    color: #4b5252;
    text-transform: uppercase;
  }

  ol,
  li {
    font-size: 1.6rem;
    line-height: 25px;
  }

  ol {
    list-style: none;
    counter-reset: custom-counter;
  }

  ol li {
    counter-increment: custom-counter;
  }

  ol li::before {
    content: counter(custom-counter, decimal-leading-zero) " ";
    color: ${props => props.theme.colors.primaryHover};
    font-weight: bold;
    padding-right: 0.8rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
    display: inline-block;
    width: 90%;
    padding: 0.8rem;

    &:hover {
      color: ${props => props.theme.colors.primaryHover};
      background-color: #f6f6f6;
    }
  }
`

const ChildList = ({ articleChildren }) => {
  const renderArticleLink = (article, articleChildren) => {
    return (
      <li key={article.id}>
        <Link to={`/learning-library/articles/${article.id}`}>
          {article.title}
        </Link>
        {articleChildren && renderChildList(articleChildren)}
      </li>
    )
  }

  const renderChildList = articleChildren => {
    return (
      <ol>
        {sortBy(articleChildren, "sort").map(article => {
          return (
            <Fragment key={article.id}>
              {renderArticleLink(article, article.children)}
            </Fragment>
          )
        })}
      </ol>
    )
  }

  return (
    <Container>
      <h4>Contents</h4>
      {renderChildList(articleChildren)}
    </Container>
  )
}

export default ChildList
