import React from "react"
import styled from "styled-components"
import useModal from "shared/hooks/useModal"
import Modal from "shared/components/Modal"

const HoverableImage = styled.img`
  &:hover {
    cursor: pointer;
  }
`

const LightboxImage = ({ src }) => {
  const { modalOpen, toggleModal } = useModal()

  return modalOpen ? (
    <Modal
      open={modalOpen}
      showCloseIcon
      closeModal={toggleModal}
      maxWidth={"90rem"}>
      <img src={`${src}?auto=format&fit=max&w=1024`} />
    </Modal>
  ) : (
    <HoverableImage
      onClick={toggleModal}
      src={`${src}?auto=format&fit=max&h=350`}
    />
  )
}
export default LightboxImage
